import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import HomeVid from '../media/videos/homepage.mp4'
import HomeVidPoster from '../media/videos/homepage_poster.jpg'
import ReactMarkdown from 'react-markdown'

// import components
import Layout from '../components/sections/layout'
import Seo from '../components/sections/seo'
import HeaderVideo from '../components/headerVideo'
import Carousel from '../components/carousel/carousel'
import HomeCtas from '../components/home/homeCtas'

// styled components
import styled from 'styled-components'
import { fontSize } from '../css/mixins/mixins'
const H1Tag = styled.h1`
  text-align: center;
  ${fontSize(26)}
  margin-top: 20px;
  margin-bottom: 60px;
  position: relative;

  @media only screen and (min-width: 930px) {
    ${fontSize(45)}

    margin-top: 80px;
    margin-bottom: 120px;
  }

  b {
    color: #896d49;
    ${fontSize(30)}

    @media only screen and (min-width: 930px) {
      ${fontSize(50)}
    }
  }

  &:after {
    content: '';
    position: absolute;
    width: 100px;
    height: 2px;
    bottom: -35px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #000;

    @media only screen and (min-width: 930px) {
      width: 150px;
    }
  }
`

const CarouselWrapper = styled.div`
  background-color: whitesmoke;

  @media only screen and (min-width: 930px) {
    padding-top: 20px;
  }
`

const seo_description = `Hove 5* rated premium Personal Training service. Personal Trainer Hove & Brighton. Level 4 Personal Trainer, 16 years experience, private studio, Brighton & Portslade.`
const seo_keywords = `NHS approved, Best Trainer Award, 5* rating, personal trainer Hove, personal training Hove, personal training Brighton, online personal training, Hove, older adults, personal trainer, Private Studio, older adults Hove, older adults Brighton, Best personal trainer Hove, Personal Trainer in Brighton, Personal Trainer in Hove, Personal Trainer Near Me, online personal trainer hove, online personal trainer brighton, PT Hove & Brighton`

const IndexPage = () => {
  const data = useStaticQuery(query)
  const { strapiHomepage } = data
  const { title, description, SEO } = strapiHomepage

  const { page_description = seo_description, page_keywords = seo_keywords, page_title = title } = SEO

  return (
    <Layout>
      <Seo title={page_title} description={page_description} keywords={page_keywords} />
      <HeaderVideo src={HomeVid} poster={HomeVidPoster} />
      <div className="container">
        <H1Tag>
          Personal Trainer.
          <br />
          <b>Hove, Brighton & West Sussex.</b>
          <br />
          Private Studio Available.
        </H1Tag>
        {description !== null && description !== '' && <ReactMarkdown children={description} />}
      </div>
      <CarouselWrapper>
        <div className="container">
          <Carousel />
        </div>
      </CarouselWrapper>
      <HomeCtas />
    </Layout>
  )
}

const query = graphql`
  {
    strapiHomepage {
      SEO {
        page_description
        page_keywords
        page_title
      }
      title
      description
    }
  }
`

export default IndexPage
