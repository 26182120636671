import React from "react"

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper"
import SwiperCore, { Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

// import custom css
import "../../css/carousel/carousel.css"

const thumbs = ["The Guardian", "Men’s Health", "Balance Magazine", "NHS", "Age UK"]

const Carousel = () => {
  // set Swiper to use autoplay
  SwiperCore.use([Autoplay])
  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      loop={true}
      autoplay={{ delay: 2000 }}
      navigation
      pagination={{
        clickable: true,
        renderBullet: function (index, className) {
          return `<span class="${className}" key="${index}"><h2><b>${thumbs[index]}</b></h2></span>`
        },
      }}
    >
      <SwiperSlide>
        <h3>
          <i>Provider of bespoke workouts for one of UKs leading news outlets</i>
        </h3>
      </SwiperSlide>
      <SwiperSlide>
        <h3>
          <i>“Voted as Britain’s Best Trainer”</i>
        </h3>
      </SwiperSlide>
      <SwiperSlide>
        <h3>
          <i>
            “Expert Personal Trainer for the number one Diabetes magazine in the
            UK”
          </i>
        </h3>
      </SwiperSlide>
      <SwiperSlide>
        <h3>
          <i>“NHS Personal Trainer helping with the fight against Covid-19”</i>
        </h3>
      </SwiperSlide>
      <SwiperSlide>
        <h3>
          <i>“Providing physical therapy for over 60’s in care homes”</i>
        </h3>
      </SwiperSlide>
    </Swiper>
  )
}
export default Carousel
