import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import styled from 'styled-components'
import { fontSize } from '../../css/mixins/mixins'

/**
 *  Adding Dynamic images
 *  https://www.gatsbyjs.com/plugins/gatsby-plugin-image/#dynamic-images
 */

const HomeCta = styled.section`
  width: 100%;
  background-color: #707070;
  margin-bottom: 30px;
`

const HomeCtaLink = styled(Link)`
  position: relative;
  display: block;

  &:hover h2 {
    background-color: #fff;
    color: #000 !important;
  }

  .gatsby-image-wrapper {
    width: 100%;
    opacity: 0.75;
  }
`

const HomeCtaClick = styled.h2`
  color: #fff !important;
  width: 100%;
  display: block;
  max-width: 250px;
  position: absolute;
  text-align: center;
  padding: 15px 30px;
  border: 2px #fff solid;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 300;
  background-color: transparent;

  transition: 0.3s background-color, 0.3s color;

  ${fontSize(20)}

  @media only screen and (min-width: 930px) {
    left: calc(50% + 55px);
    ${fontSize(28)}
    max-width: 300px;
  }
`

const HomeCtas = () => {
  return (
    <>
      <HomeCta>
        <HomeCtaLink to={`about`}>
          <StaticImage src="../../media/images/home_cta_about.jpg" alt="Ty Paul Personal Training working out in a garden" quality="80" />
          <HomeCtaClick className="home-cta__click">The Team</HomeCtaClick>
        </HomeCtaLink>
      </HomeCta>
      <HomeCta>
        <HomeCtaLink to={`location/flansham`}>
          <StaticImage src="../../media/images/flansham_photo_1.jpg" alt="Gym equipment inside besoke studio gym in Flansham" quality="80" />
          <HomeCtaClick className="home-cta__click">Flansham Location</HomeCtaClick>
        </HomeCtaLink>
      </HomeCta>
      <HomeCta>
        <HomeCtaLink to={`location/hove`}>
          <StaticImage src="../../media/images/hove_photo_1.jpg" alt="Gym equipment inside Hove based studio gym" quality="80" />
          <HomeCtaClick className="home-cta__click">Hove Location</HomeCtaClick>
        </HomeCtaLink>
      </HomeCta>
      <HomeCta>
        <HomeCtaLink to={`bootcamp`}>
          <StaticImage src="../../media/images/flansham_photo_4.jpg" alt="Tennis racket on shelf inside bespoke gym" quality="80" />
          <HomeCtaClick className="home-cta__click">Bootcamp</HomeCtaClick>
        </HomeCtaLink>
      </HomeCta>
      <HomeCta>
        <HomeCtaLink to={`personal-training`}>
          <StaticImage src="../../media/images/home_cta_personal_training.jpg" alt="Football player training with equipment" quality="80" />
          <HomeCtaClick className="home-cta__click">Personal Training</HomeCtaClick>
        </HomeCtaLink>
      </HomeCta>
      <HomeCta>
        <HomeCtaLink to={`rehabilitation`}>
          <StaticImage src="../../media/images/home_cta_rehabilitation.jpg" alt="Therapy equipment being used on a persons back" quality="80" />
          <HomeCtaClick className="home-cta__click">Rehabilitation</HomeCtaClick>
        </HomeCtaLink>
      </HomeCta>
      <HomeCta>
        <HomeCtaLink to={`60s-plus-club`}>
          <StaticImage src="../../media/images/home_cta_over_60.jpg" alt="Laptop showing Zoom call for a virtual personal training session" quality="80" />
          <HomeCtaClick className="home-cta__click">60s+ Club</HomeCtaClick>
        </HomeCtaLink>
      </HomeCta>
      <HomeCta>
        <HomeCtaLink to={`virtual-personal-training`}>
          <StaticImage src="../../media/images/home_cta_virtual_personal_training.jpg" alt="Man working out in a sports specific training session" quality="80" />
          <HomeCtaClick className="home-cta__click">Virtual Personal Training</HomeCtaClick>
        </HomeCtaLink>
      </HomeCta>
      <HomeCta>
        <HomeCtaLink to={`sports-specific-training`}>
          <StaticImage src="../../media/images/home_cta_sport_training.jpg" alt="White signed England t-shirt" quality="80" />
          <HomeCtaClick className="home-cta__click">Sports Specific Training</HomeCtaClick>
        </HomeCtaLink>
      </HomeCta>
      <HomeCta>
        <HomeCtaLink to={`prices`}>
          <StaticImage src="../../media/images/home_cta_prices.jpg" alt="Three black bottles lined up with Ty Paul written on each bottle" quality="80" />
          <HomeCtaClick className="home-cta__click">Prices</HomeCtaClick>
        </HomeCtaLink>
      </HomeCta>
      <HomeCta>
        <HomeCtaLink to={`fitness-journal`}>
          <StaticImage src="../../media/images/home_cta_blog.jpg" alt="Ty Paul working out with a set of dumbbell" quality="80" />
          <HomeCtaClick className="home-cta__click">Fitness Journal</HomeCtaClick>
        </HomeCtaLink>
      </HomeCta>
      <HomeCta>
        <HomeCtaLink to={`contact`}>
          <StaticImage src="../../media/images/home_cta_contact.jpg" alt="Brick wall with Ty Paul's logo attached to it" quality="80" />
          <HomeCtaClick className="home-cta__click">Contact</HomeCtaClick>
        </HomeCtaLink>
      </HomeCta>
    </>
  )
}

export default HomeCtas
